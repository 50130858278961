
export default {
  basicInformation: [{
    prop: 'online',
    back: true
  }, {
    prop: 'name'
  }, {
    prop: 'name_en'
  }, {
    prop: 'lat'
  }, {
    prop: 'lon'
  }, {
    prop: 'description',
    span: 24
  }, {
    prop: 'description_en',
    span: 24
  }, {
    prop: 'title',
    span: 12
  }, {
    prop: 'title_en',
    span: 12
  }, {
    prop: 'price'
  }, {
    prop: 'rating'
  }, {
    prop: 'address'
  }, {
    prop: 'website'
  }, {
    prop: 'contact_phone'
  }, {
    prop: 'email'
  }, {
    prop: 'open',
    span: 24
  }, {
    prop: 'open_en',
    span: 24
  }, {
    prop: 'traffic_strategy',
    span: 24
  }, {
    prop: 'traffic_strategy_en',
    span: 24
  }, {
    prop: 'tips',
    span: 24
  }, {
    prop: 'tips_en',
    span: 24
  }, {
    prop: 'global_labels'
  }, {
    prop: 'redeem_code'
  }, {
    prop: 'created_at'
  }, {
    prop: 'updated_at'
  }, {
    prop: 'image',
    image: true,
    span: 24
  }],
  chope_information: [{
    prop: 'chope',
    back: true
  }, {
    prop: 'restaurant_id',
  }, {
    prop: 'multiple_chope_id',
    back: true
  }, {
    prop: 'chope_id_list',
    span: 24
  }],
  tableDataViewItem: [{
    prop: 'name',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'name_en',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'description',
    label: '',
    Object: 'value',
    width: '200'
  }, {
    prop: 'price_f',
    Object: 'value',
    label: '',
    width: '100'
  }, {
    prop: 'image_url',
    img: true,
    label: '',
    width: '160'
  }, {
    prop: 'online',
    Object: 'value',
    label: '',
    width: '80',
    disabled: true
  }]
}
